import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "daysLabel", "priceLabel", "cardErrors", "paypalMonthsField", "oxxoMonthsField", "oxxoFemsaMonthsField", "speiMonthsField"]
  static values = {
    price: Number,
    currency: String,
    stripeKey: String,
    clientName: String,
    clientEmail: String
  }

  initialize() {
    this.stripe = Stripe(this.stripeKeyValue)
    this.currentMonths = 1

    const elements = this.stripe.elements({
      locale: 'es'
    })

    var style = {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      }
    }

    this.cardElement = elements.create("card", { style: style })
    this.cardElement.mount("#card-element")

    this.cardElement.on('change', function(event) {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message
      } else {
        this.cardErrorsTarget.textContent = ''
      }
    }.bind(this))
  }

  updateAmounts(e) {
    let months = e.currentTarget.value
    if (months == 0) {
      return
    }

    this.currentMonths = months
    this.daysLabelTarget.textContent = `${months * 30} días:`
    this.priceLabelTarget.textContent = `$${months * this.priceValue} ${this.currencyValue}`

    // paypal
    this.paypalMonthsFieldTarget.value = months

    // oxxo
    this.oxxoMonthsFieldTarget.value = months

    // oxxo femsa
    this.oxxoFemsaMonthsFieldTarget.value = months

    // spei
    this.speiMonthsFieldTarget.value = months
  }

  submitCardForm(e) {
    e.preventDefault()
    e.stopPropagation()

    $.rails.disableFormElements(this.$cardForm)

    $.ajax({
      type: "POST",
      url: '/monthly_accesses/stripe_intent',
      data: {
        months: this.currentMonths
      }
    }).done((response) => {
      this.handleStripePayment(response.stripe_secret)
    })
  }

  handleStripePayment(stripe_secret) {
    this.stripe.confirmCardPayment(stripe_secret, {
      payment_method: {
        card: this.cardElement,
        billing_details: {
          name: this.clientNameValue,
          email: this.clientEmailValue
        }
      }
    }).then(function(result) {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        this.cardErrorsTarget.textContent = result.error.message
        $.rails.enableFormElements(this.$cardForm)
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          this.$cardForm.append(`<input type="hidden" name="stripe_payment_id" value="${result.paymentIntent.id}">`)
    
          $.ajax({
            type: "POST",
            url: this.$cardForm.attr("action"),
            data: this.$cardForm.serialize()
          }).done((response) => {
            return window.location = "/cuenta?success=true"
          }).fail((response) => {
            this.cardErrorsTarget.textContent = "El pago no pudo ser realizado, por favor vuelve a intentarlo."
            $.rails.enableFormElements(this.$cardForm)
          })
        }
      }
    }.bind(this))
  }

  get $cardForm() {
    return $('.manual-card-form')
  }
}
