import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "cardErrors" ]
  static values = {
    stripeKey: String,
    stripeClientSecret: String,
    clientEmail: String,
  }

  initialize() {
    this.stripe = Stripe(this.stripeKeyValue)

    const elements = this.stripe.elements({
      locale: 'es'
    })

    var style = {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      }
    }

    this.cardElement = elements.create("card", { style: style })
    this.cardElement.mount("#card-element")

    this.cardElement.on('change', function(event) {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message
      } else {
        this.cardErrorsTarget.textContent = ''
      }
    }.bind(this))
  }

  submitCardForm(e) {
    e.preventDefault()
    e.stopPropagation()

    $.rails.disableFormElements(this.$cardForm)

    this.stripe.confirmCardPayment(this.stripeClientSecretValue, {
      payment_method: {
        card: this.cardElement,
        billing_details: {
          email: this.clientEmailValue
        }
      }
    }).then(function(result) {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        this.cardErrorsTarget.textContent = result.error.message
        $.rails.enableFormElements(this.$cardForm)
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          this.$cardForm.append(`<input type="hidden" name="stripe_payment_id" value="${result.paymentIntent.id}">`)
    
          $.ajax({
            type: "POST",
            url: this.$cardForm.attr("action"),
            data: this.$cardForm.serialize()
          }).done((response) => {
            return window.location = "/concurso/new?s=1"
          }).fail((response) => {
            this.cardErrorsTarget.textContent = "El pago no pudo ser realizado, por favor vuelve a intentarlo."
            $.rails.enableFormElements(this.$cardForm)
          })
        }
      }
    }.bind(this))
  }

  get $cardForm() {
    return $('.card-form')
  }
}
