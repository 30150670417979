import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    if (!localStorage.getItem("_acordeonisticos-hide-contest-bar")) {
      this.element.classList.remove("d-none")
      this.element.classList.add("d-block")
    }
  }

  closeBar(e) {
    e.preventDefault()

    this.element.classList.add('d-none')
    localStorage.setItem("_acordeonisticos-hide-contest-bar", true)
  }
}
